import Button from '@material-ui/core/Button/Button';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';

class RemoveButton extends React.Component {
  state = {
    submitting: false,
  };

  render() {
    const {classes, onRemove} = this.props;
    const {submitting} = this.state;

    return (
      <div className={classes.statusWrapper}>
        <Button
          className={classes.actionButton}
          color="secondary"
          disabled={submitting}
          size="small"
          variant="outlined"
          onClick={() => {
            this.setState({submitting: true});
            onRemove().then(() => {
              this.setState({submitting: false});
            });
          }}
        >
          Удалить задачу
        </Button>
      </div>
    );
  }
}

const styles = () => ({
  statusWrapper: {
    padding: '0 24px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export default withStyles(styles)(RemoveButton);

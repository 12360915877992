import Button from '@material-ui/core/Button/Button';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {colors} from '@material-ui/core';

class StatusButton extends React.Component {
  state = {
    saving: false,
  };

  render() {
    const {classes, closed, permissions, onClose, onReopen} = this.props;
    const {saving} = this.state;

    return (
      <div className={classes.statusWrapper}>
        <p className={classes.statusLabel}>
          {closed ? 'Задача закрыта' : 'Задача открыта'}
        </p>
        <Button
          className={closed ? classes.redButton : classes.greenButton}
          color="default"
          disabled={
            saving || !permissions.canCloseTask || !permissions.canReopenTask
          }
          size="small"
          variant="outlined"
          onClick={() => {
            this.setState({saving: true});
            (closed ? onReopen() : onClose()).then(() => {
              this.setState({saving: false});
            });
          }}
        >
          {closed ? 'Открыть' : 'Закрыть'}
        </Button>
      </div>
    );
  }
}

const styles = () => ({
  statusWrapper: {
    padding: '0 24px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  redButton: {
    color: colors.red[500],
    borderColor: colors.red[500],
  },
  greenButton: {
    color: colors.green[500],
    borderColor: colors.green[500],
  },
});

export default withStyles(styles)(StatusButton);
